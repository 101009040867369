<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-btn
      color="#aaa"
      class="my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left
      </v-icon>
      Back
    </v-btn>
    <h2 class="title-style pt-6">
      Details
    </h2>
    <v-tabs
      v-model="tab"
      class="mt-5"
    >
      <v-tab>
        <v-icon>mdi-file-document-outline</v-icon>
        Documents
      </v-tab>
      <v-tab>
        <v-icon>mdi-transit-transfer</v-icon>
        Activities
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <app-user-documents />
      </v-tab-item>
      <v-tab-item>
        <app-user-activities />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
  import AppUserDocuments from 'src/views/dashboard/appusers/AppUserDocuments';
  import AppUserActivities from 'src/views/dashboard/appusers/AppUserActivities.vue';
  import ModuleHelper from 'src/helpers/module-helper';
  import global from 'src/mixins/global';

  export default {
    name: 'AppUserLandingPage',
    components: {
      'app-user-activities': AppUserActivities,
      'app-user-documents': AppUserDocuments,
    },
    mixins: [global],
    data: () => ({
      showDocumentsUploaded: true,
      selectedItem: -1,
      showActivities: false,
      helper: new ModuleHelper(),
      tab: null,
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
    },
    mounted () {
      this.selectedItem = 0;
    },
    methods: {
      back () {
        this.$router.push({ name: 'App Users' });
      },
      getAllDocumentsUploaded () {
        this.showActivities = false;
        this.showDocumentsUploaded = true;
        },
      getAppUserActivities () {
        this.showDocumentsUploaded = false;
        this.showActivities = true;
      },
    },
  };
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.my-back-btn-style {
  margin-bottom: -20px;
}
@media (max-width: 960px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
}
.premium-style {
  font-size:25px;
  color:#006883!important;
  font-weight:bold;
}
.premium-container{
  background-color: #ffffff!important;
  border-radius: 50%;
}
.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
