import { render, staticRenderFns } from "./AppUserAllActivities.vue?vue&type=template&id=159b13a8&scoped=true"
import script from "./AppUserAllActivities.vue?vue&type=script&lang=js"
export * from "./AppUserAllActivities.vue?vue&type=script&lang=js"
import style0 from "./AppUserAllActivities.vue?vue&type=style&index=0&id=159b13a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "159b13a8",
  null
  
)

export default component.exports